import { Button, Card, Container, Stack, Typography } from "@mui/material";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";

const FormConfirmation = ({ appState }) => {
  const { formValues, numberOfAPM, numberOfRL } = appState;
  const navigate = useNavigate();
  const handleSubmit = (appState) => {
    console.log(appState);
    axios
      .post(`${process.env.REACT_APP_API_URL}/retailer`, {
        numberOfAPM,
        numberOfRL,
        ...formValues,
      })
      .then(() => {
        navigate("/complete");
      })
      .catch(() => console.log("error"));
  };
  return (
    <Container sx={{ py: 4 }}>
      <Typography sx={{ pb: 2 }} align="center">
        Please confirm the details below.
      </Typography>
      <Card sx={{ p: 4 }}>
        <Stack spacing={2}>
          <Typography>
            <b>Legal Business Name:</b> {formValues.legalBusinessName}
          </Typography>
          <Typography>
            <b>Doing Business As:</b> {formValues.doingBusinessAs || "N/A"}
          </Typography>
          <Typography>
            <b>Sales Tax Permit Number:</b> {formValues.resellerNumber}
          </Typography>
          <Typography>
            <b>Principal Address:</b> {formValues.pAStreetName},{" "}
            {formValues.pACity}, {formValues.pAState} {formValues.pAZip}
          </Typography>
          <Typography>
            <b>Account Owner Name:</b> {formValues.accountOwnerName}
          </Typography>
          <Typography>
            <b>Account Owner Email:</b> {formValues.accountOwnerEmail}
          </Typography>
          <Typography>
            <b>Account Owner Phone Number:</b>{" "}
            {formValues.accountOwnerPhoneNumber}
          </Typography>
          {Array.from(Array(numberOfAPM), (e, i) => {
            return (
              <React.Fragment key={`apm-${i}`}>
                <Typography>
                  <b>
                    Authorized Purchasing Manager {numberOfAPM > 1 ? i + 1 : ""}{" "}
                    Name:
                  </b>{" "}
                  {formValues[`apm${i + 1}Name`]}
                </Typography>
                <Typography>
                  <b>
                    Authorized Purchasing Manager {numberOfAPM > 1 ? i + 1 : ""}{" "}
                    Email:
                  </b>{" "}
                  {formValues[`apm${i + 1}Email`]}
                </Typography>
                <Typography>
                  <b>
                    Authorized Purchasing Manager {numberOfAPM > 1 ? i + 1 : ""}{" "}
                    Phone Number:
                  </b>{" "}
                  {formValues[`apm${i + 1}PhoneNumber`]}
                </Typography>
              </React.Fragment>
            );
          })}
          {Array.from(Array(numberOfRL), (e, i) => {
            return (
              <Typography key={`rl-${i}`}>
                <b>Retail Address {numberOfRL > 1 ? i + 1 : ""}:</b>{" "}
                {formValues[`rl${i + 1}StreetName`]},{" "}
                {formValues[`rl${i + 1}City`]}, {formValues[`rl${i + 1}State`]}{" "}
                {formValues[`rl${i + 1}Zip`]}
              </Typography>
            );
          })}
        </Stack>
      </Card>
      <Stack direction="row" justifyContent="space-between" sx={{ pt: 2 }}>
        <Button onClick={() => navigate("/")} color="error">
          Edit
        </Button>
        <Button onClick={() => handleSubmit(appState)} variant="contained">
          Submit
        </Button>
      </Stack>
    </Container>
  );
};

export default FormConfirmation;
