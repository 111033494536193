import { Container, Typography } from "@mui/material";
import React from "react";

const ThankYou = () => {
  return (
    <Container maxWidth="md" sx={{ p: 4 }}>
      <Typography>
        Thank you for submitting your form. You should receive a follow up email
        shortly with our Retailer Agreement.
      </Typography>
    </Container>
  );
};

export default ThankYou;
