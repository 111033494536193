import InfoIcon from "@mui/icons-material/Info";
import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import logo from "./peluva-logo.png";

const RetailerAgreementForm = ({ setAppState, appState }) => {
  const { formValues, numberOfAPM, numberOfRL, differentAPM } = appState;

  const navigate = useNavigate();
  const { register, handleSubmit } = useForm({
    defaultValues: formValues,
  });
  const onSubmit = (data) => {
    console.log(data);

    if (!differentAPM) {
      setAppState((prevState) => ({
        ...prevState,
        numberOfAPM: 0,
      }));
    }
    setAppState((prevState) => ({ ...prevState, formValues: data }));
    navigate("/confirm");
  };
  useEffect(() => {}, [differentAPM, numberOfAPM, numberOfRL]);
  return (
    <Container maxWidth="md" sx={{ p: 4 }}>
      <img src={logo} alt="peluva logo" style={{ width: "40%" }} />
      <Typography align="center" variant="h1" fontSize={32} pb={4}>
        Peluva Retailer Information Form
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <Typography variant="h6" align="left" gutterBottom>
            General Business Information
          </Typography>

          <Stack spacing={2}>
            <TextField
              label="Legal Buisness Name"
              variant="outlined"
              required
              {...register("legalBusinessName")}
            />

            <TextField
              label="Doing Business As"
              variant="outlined"
              {...register("doingBusinessAs")}
            />

            <TextField
              label="Sales Tax Permit Number (Reseller Number)"
              variant="outlined"
              required
              {...register("resellerNumber")}
            />
            <Typography variant="h6" align="left" gutterBottom>
              Principal Address
              <Tooltip
                title="Address for invoicing and notifications"
                placement="right"
              >
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Typography>
            <TextField
              label="Street"
              variant="outlined"
              required
              {...register("pAStreetName")}
            />
            <TextField
              label="City"
              variant="outlined"
              required
              {...register("pACity")}
            />
            <TextField
              label="State"
              variant="outlined"
              required
              {...register("pAState")}
            />
            <TextField
              label="Zip/Postal Code"
              variant="outlined"
              required
              {...register("pAZip")}
            />
          </Stack>

          {/* Account Owner Information Start */}
          <Typography variant="h6" align="left">
            Account Owner Information
            <Tooltip
              title="Top level contact for the account. This email will be used to send contracts."
              placement="right"
            >
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Typography>

          <Stack spacing={2}>
            <TextField
              label="Name"
              variant="outlined"
              required
              {...register("accountOwnerName")}
            />
            <TextField
              label="Email"
              variant="outlined"
              required
              {...register("accountOwnerEmail")}
            />
            <TextField
              label="Phone Number"
              variant="outlined"
              required
              {...register("accountOwnerPhoneNumber")}
            />
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={differentAPM}
                    onChange={(e) => {
                      setAppState((prevState) => ({
                        ...prevState,
                        differentAPM: e.target.checked,
                        numberOfAPM:
                          prevState.numberOfAPM < 1 ? 1 : prevState.numberOfAPM,
                      }));
                    }}
                  />
                }
                label="Authorized Purchasing Manager (Buyer) is different from Account Owner"
              />
            </FormGroup>
          </Stack>

          {/* Account Owner Information End */}
          {differentAPM && (
            <>
              <Typography variant="h6" align="left">
                Authorized Purchasing Manager Information
                <Tooltip
                  title="Add the following fields for each buyer on the account."
                  placement="right"
                >
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </Typography>

              {Array.from(Array(numberOfAPM), (e, i) => {
                return (
                  <React.Fragment key={`apm-${i}`}>
                    {numberOfAPM > 1 ? (
                      <>
                        <Typography align="center">
                          Authorized Purchasing Manager {i + 1}
                        </Typography>

                        <Stack spacing={2} sx={{ mb: 4 }}>
                          <TextField
                            label="Name"
                            variant="outlined"
                            required
                            {...register(`apm${i + 1}Name`)}
                          />
                          <TextField
                            label="Email"
                            variant="outlined"
                            required
                            {...register(`apm${i + 1}Email`)}
                          />
                          <TextField
                            label="Phone Number"
                            variant="outlined"
                            required
                            {...register(`apm${i + 1}PhoneNumber`)}
                          />
                        </Stack>
                      </>
                    ) : (
                      <Stack spacing={2}>
                        <TextField
                          label="Name"
                          variant="outlined"
                          required
                          {...register(`apm${i + 1}Name`)}
                        />
                        <TextField
                          label="Email"
                          variant="outlined"
                          required
                          {...register(`apm${i + 1}Email`)}
                        />
                        <TextField
                          label="Phone Number"
                          variant="outlined"
                          required
                          {...register(`apm${i + 1}PhoneNumber`)}
                        />
                      </Stack>
                    )}
                  </React.Fragment>
                );
              })}
              <Button
                onClick={() =>
                  setAppState((prevState) => ({
                    ...prevState,
                    numberOfAPM: prevState.numberOfAPM + 1,
                  }))
                }
              >
                Add Another Authorized Purchasing Manager
              </Button>
              {numberOfAPM > 1 && (
                <Button
                  color="error"
                  onClick={() =>
                    setAppState((prevState) => ({
                      ...prevState,
                      numberOfAPM: prevState.numberOfAPM - 1,
                    }))
                  }
                >
                  Remove Last Authorized Purchasing Manager
                </Button>
              )}
            </>
          )}
          {/* Retail Location Start */}
          <Typography variant="h6" align="left">
            Retail Location Information
            <Tooltip
              title="Complete the following fields for each address that will accept shipments from peluva."
              placement="right"
            >
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Typography>

          {Array.from(Array(numberOfRL), (e, i) => {
            return (
              <React.Fragment key={`rl-${i}`}>
                {numberOfRL > 1 ? (
                  <>
                    <Typography align="center">
                      Retail Location {i + 1}
                    </Typography>
                    <Stack spacing={2} sx={{ mb: 4 }}>
                      <TextField
                        label="Street"
                        variant="outlined"
                        required
                        {...register(`rl${i + 1}StreetName`)}
                      />
                      <TextField
                        label="City"
                        variant="outlined"
                        required
                        {...register(`rl${i + 1}City`)}
                      />
                      <TextField
                        label="State"
                        variant="outlined"
                        required
                        {...register(`rl${i + 1}State`)}
                      />
                      <TextField
                        label="Zip/Postal Code"
                        variant="outlined"
                        required
                        {...register(`rl${i + 1}Zip`)}
                      />
                    </Stack>
                  </>
                ) : (
                  <Stack spacing={2}>
                    <TextField
                      label="Street"
                      variant="outlined"
                      required
                      {...register(`rl${i + 1}StreetName`)}
                    />
                    <TextField
                      label="City"
                      variant="outlined"
                      required
                      {...register(`rl${i + 1}City`)}
                    />
                    <TextField
                      label="State"
                      variant="outlined"
                      required
                      {...register(`rl${i + 1}State`)}
                    />
                    <TextField
                      label="Zip/Postal Code"
                      variant="outlined"
                      required
                      {...register(`rl${i + 1}Zip`)}
                    />
                  </Stack>
                )}
              </React.Fragment>
            );
          })}
          <Button
            onClick={() =>
              setAppState((prevState) => ({
                ...prevState,
                numberOfRL: prevState.numberOfRL + 1,
              }))
            }
          >
            Add Another Retail Location
          </Button>
          {numberOfRL > 1 && (
            <Button
              color="error"
              onClick={() =>
                setAppState((prevState) => ({
                  ...prevState,
                  numberOfRL: prevState.numberOfRL - 1,
                }))
              }
            >
              Remove Last Retail Location
            </Button>
          )}

          {/* Retail Location End */}
          <Button type="submit" variant="contained">
            Submit
          </Button>
        </Stack>
      </form>
    </Container>
  );
};

export default RetailerAgreementForm;
