import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import RetailerAgreementForm from "./RetailerAgreementForm";

import React, { useState } from "react";
import FormConfirmation from "./FormConfirmation";
import ThankYou from "./ThankYou";

const App = () => {
  const defaultValues = {
    accountOwnerEmail: "",
    accountOwnerName: "",
    accountOwnerPhoneNumber: "",
    doingBusinessAs: "",
    legalBusinessName: "",
    pACity: "",
    pAState: "",
    pAStreetName: "",
    pAZip: "",
    resellerNumber: "",
    rl1City: "",
    rl1State: "",
    rl1StreetName: "",
    rl1Zip: "",
  };

  const [appState, setAppState] = useState({
    formValues: defaultValues,
    numberOfAPM: 1,
    differentAPM: false,
    numberOfRL: 1,
  });
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          exact
          element={
            <RetailerAgreementForm
              appState={appState}
              setAppState={setAppState}
            />
          }
        />

        <Route
          path="/confirm"
          element={
            <FormConfirmation appState={appState} setAppState={setAppState} />
          }
        />
        <Route path="/complete" element={<ThankYou />} />
      </Routes>
    </Router>
  );
};

export default App;
